import React from "react";
import Img from "gatsby-image";
const Service = ({ data, order }) => {
  return (
    <div
      className={`flex w-full ${
        order === 1 ? "flex-wrap" : "flex-wrap-reverse"
      } justify-between service-parent bg-white pt-8 md:py-12 my-8`}
    >
      {order === 1 ? (
        <React.Fragment>
          <div className="content p-5 flex flex-col md:items-center justify-center">
            <h2 className="font-bold text-4xl Baloo md:text-right leading-tight uppercase">
              {data?.service_title}
            </h2>
            <p className="font-medium text-lg Baloo md:text-right mt-2">
              {data?.service_description}
            </p>
          </div>
          {data?.service_images?.map((image) => {
            return (
              <div className="image">
                <Img
                  className="w-full"
                  style={{ height: "100%" }}
                  fluid={image?.childImageSharp?.fluid}
                />
              </div>
            );
          })}{" "}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {data?.service_images?.map((image) => {
            return (
              <div className="image">
                <Img
                  className="w-full"
                  style={{ height: "100%" }}
                  fluid={image?.childImageSharp?.fluid}
                />
              </div>
            );
          })}
          <div className="content p-5 flex flex-col md:items-center justify-center">
            <h2 className="font-bold text-4xl Baloo text-left leading-tight uppercase">
              {data?.service_title}
            </h2>
            <p className="font-medium text-lg Baloo text-left mt-2">
              {data?.service_description}
            </p>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Service;
